

























import { Vue, Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Logo, Textfield, Spinner } from '@components/UI';
import { LoginErrors } from '@shared/config/errors';
import VueRecaptcha from 'vue-recaptcha';

@Component({
    components: { Logo, Textfield, Spinner, VueRecaptcha }
})
export default class SignUpView extends Vue {

    private username: string = '';
    private discordUsername: string = '';
    private password: string = '';
    private gresp: string = '';

    private siteKey: string = '6LdproQUAAAAAPV-ulBLp4AxR9LHvVZoYztrfBR2';

    @Action
    private register: (any) => Promise<any>;

    private loading: boolean = false;
    private error: string = '';
    
    private async onSubmit(): Promise<any> {
        if (this.canSubmit) {
            try {
                this.loading = true;
                const response = await this.register({ username: this.username, password: this.password, discordId: this.discordUsername, gresp: this.gresp });

                this.loading = false;
                this.error = '';
                this.$router.push('/authentication/signin');
            } catch (error) {
                this.loading = false;
                this.error = error.errorCode;
            }
        }
    }

    private get errorMessage(): string | null {
        if (this.error) {
            if (LoginErrors[this.error])
                return LoginErrors[this.error];
            
            return 'An Unknown Error Ocurred';
        }
        
        return null;
    }

    private onVerify(response) {
        this.gresp = response;
    }

    private get canSubmit(): boolean {
        return this.username.length > 0 && this.password.length > 0 && this.discordUsername.length > 0 && this.gresp.length > 0;
    }

}

